import React from "react";
import { clearLocaleStorageInEstimateSection } from "../utils/page.utils";

interface Props {
  onContinue:() => void;
  onOpenCurrentPurchase:() => void;
}
const CurrentPruchaseConfirmationModal = (props: Props) => {
  const heading='Alert';
  const text='You already have an active current project. Are you sure you want to switch to a new one?';

  return (
    <section className="confirm-box relative md:container md:m-auto mt-6 mb-6 md:mt-0">
      <div className="bg-white px-4 sm:px-6">
        <div className="mb-4">
          <h2 className="text-xl text-titleColor font-primaryMedium">
            {" "}
            {heading}
          </h2>

          <p className="text-titleColor text-base pt-2 xxs:pt-3">
            {" "}
            {text}{" "}
          </p>
        </div>
        <div className="xxs:justify-end xxs:items-center dialog-footer flex flex-col xxs:flex-row xxs:border-t pt-3 border-opacity-5 border-bodyColor">
            <button
              className="w-full xxs:w-auto border border-primary px-2 sm:px-4 py-2  bg-opacity-10 text-center text-primary focus:outline-none font-primaryMedium rounded transition ease-in-out delay-150 hover:bg-opacity-20 text-sm"
              onClick={() => {
                props.onOpenCurrentPurchase();
              }}
            >
              Open Current Project
            </button>
            <button
              className="xxs:ml-3 mt-2 xxs:mt-0 w-full xxs:w-auto border border-transparent px-2 sm:px-4 py-2 bg-primary font-primaryMedium rounded text-white hover:bg-opacity-90 focus:outline-none text-sm"
              onClick={() => {
                // localStorage.clear()
                clearLocaleStorageInEstimateSection();
                props.onContinue();
              }}
            >
              Ignore And Continue
            </button>
        </div>
      </div>
    </section>
  );
};

export default CurrentPruchaseConfirmationModal;