export const SQ_STATIC_PAGES=[
  'privacy',
  'blog',
  'calculator',
  'resources',
  'about',
  'battery',
  'charger',
  'company',
  'equity-resilience',
  'get-quote',
  'lead',
  'missionsolar',
  'solar',
  'storz',
  'support',
  'whySolar'
];

export const CLIENT_ACCESS_ROUTES = [
  "estimate",
  "instant-estimate",
  "instant-estimate-result",
  "account",
  "ambassador",
  "self-site-survey",
  "proposal",
  "referral",
  "profile",
  "auth"
];

export const WITH_HEADER_PAGE=[
  "estimate",
  "instant-estimate",
  "instant-estimate-result",
  "account",
  "self-site-survey",
  "profile"
]

export const DYNAMIC_PAGES=[
  "ambassador",
  "referral",
  "home",
  "thank-you"
]


export function showHeaderOnPages(route: string) {
  const pageRoute=route.includes('profile')? 'profile': route.split('/')[1];

  return [...SQ_STATIC_PAGES,...WITH_HEADER_PAGE].includes(pageRoute);
}

export function isDynamicPage(route:string) {
  if(route==="/") {
    return true;
  }

  return DYNAMIC_PAGES.findIndex((page) => route.includes(page))>-1;
}

export function isSQStaticPage(route:string){
  const pageRoute = route.split('/')[1];
  return SQ_STATIC_PAGES.includes(pageRoute)
}

export function clearLocaleStorageInEstimateSection(){
  const getAmbassadorLoginToken= localStorage.getItem('ambassadorToken')||null;
  localStorage.clear();
  if(getAmbassadorLoginToken){
    localStorage.setItem('ambassadorToken', getAmbassadorLoginToken)
  }
} 

